$main-white: white;

$login-side-bar: #333;
$login-main-bg: #030817;
$login-btn-bg: #102d54;
$login-btn-bg-hover: #224069;

$main-footer-bg: #333333;
$main-user-bg: #030817;
$main-user-name-bg: #7f7f7f;
$main-user-btn: #030817;
$main-user-btn-hover: #283357;
$main-secondary-btn: rgb(0, 108, 155);
$main-standard-btn-disabled: #7f7f7f;

$app-tile-bg: #102d54;
$app-tile-bg-hover: #283357;
$app-tile-btn: #102d54;
$app-tile-btn-hover: #283357;

$logo-theme-black-transparent: #3130307a;