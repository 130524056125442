@use '_colors';

.login-left {
    background-color: colors.$login-side-bar;
    height: 100%;
    color: white;
    justify-content: center;
}

.login-main {
    background-color: colors.$login-main-bg;
    background-image: url('../images/circuits.png');
    background-position: 70% 40%;
    color: white;
    height: 100%;
}

.login-banner {
    width: 50%;
    margin-bottom: 2rem;
}

.login-banner-title {
    font-weight: bold;
    font-size: 2rem;
    text-decoration: underline;
    margin-bottom: 2rem;
}

.login-banner-text {
    font-size: 1.25rem;
}

.login-link {
    color: 'yellow'
}

.container {
    height: 100vh;
}

.main-container {
    display: grid;
	height: 100%;
    width: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: 87% 13%;
	grid-gap: 0px 0px;
	grid-template-areas: "app-main" "app-footer";
}

.app-main {
    display: grid;
    grid-area: app-main;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    grid-template-areas: "app-user app-display";
}

.app-user {
    grid-area: app-user;
    background-color: colors.$main-user-bg;
}

.app-display {
    grid-area: app-display;
    background-color: colors.$main-white;
    padding: 1rem;
    overflow: hidden;
}

.app-footer {
    grid-area: app-footer;
    background-color: colors.$main-footer-bg;
    color: colors.$main-white;
}

.app-footer-disclaimer {
    padding: 0 0 10px 20px;
}

.app-footer-contact {
    padding: 10px 0 0 20px;
}

.app-footer-contact-email {
    padding-left: 10px;
}

.app-footer-contact-phone {
    padding: 0 10px 0 20px;
}

.app-user-title {
    background-color: colors.$main-footer-bg;
    color: colors.$main-white;
    font-size: 1.75rem;
    width: 100%;
    height: 8%;
    font-weight: bold;
}

.app-user-name {
    background-color: colors.$main-user-name-bg;
    color: colors.$main-white;
    width: 100%;
    height: 5%;
    font-weight: bold;
}

.app-disp-title {
    font-size: 1.75rem;
    border-bottom: 1px solid black;
    font-weight: bold;
}

.app-disp-container {
    height: 80%;
    overflow-y: auto;
}

.app-disp-announcement-container {
    background-color: #ffffff;
    width: 75%;
    margin-top: 1rem;
    border: 3px solid #000000;
}

.app-disp-announcement-text {
    margin: 0.5rem 1rem 0.5rem;
}

.error-main {
    background-color: colors.$login-main-bg;
    background-image: url('../images/circuits.png');
    color: white;
    height: 100vh;
}