@use '_colors';

@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
}

.application-tile-body {
    float: left;
    position: relative;
    margin: 50px;
    width: 350px;
    height: 160px;
    background-color: colors.$app-tile-bg;
    &:hover {
        background-color: colors.$app-tile-bg-hover;
    }
}

.application-tile-body:hover .link-tile, .application-tile-body:hover  .epic-url{
    visibility: visible;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.application-tile-header {
    padding: 15px;
    font-weight: bold;
    font-size: 1.25em;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
}

.application-tile-icon {
    float: left;
    margin: 15px;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$main-white;
}

.application-tile-admin-icon {
    position: absolute;
    top: 22px;
    right: 5px;
}

.application-tile-guide-url {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.application-tile-admin-link {
    margin-top: 5px;
}

.tile-title {
    font-size: 1.5em;
    text-decoration: none;
    color: white;
    display: block;
    margin-top: 30px;
    width: 85%;
    text-align: right;
}

.application-title {
    color: white;
    font-size: 1.5em;
    text-decoration: none;
    margin-top: 15px;
    padding-right: 10px;
    text-align: right;
}

.section-tile-notification-scroll-container {
    min-height: 10vh;
    max-height: 52vh;
    width: 100%;
    overflow-y: auto;
}

.section-tile-community-chat-text {
    position: relative;
    width: 90%;
    left: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.link-tile{
    visibility: hidden;
    margin-right: 5px;
    margin-top: -10px;
    display: flex;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    color: white;
    align-items: center;
    text-decoration: none;
    height: 40px;
}

.link-tile-title{
    display: flex;
    visibility: hidden;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    text-decoration: none;
    background-color: colors.$logo-theme-black-transparent;
    height: 100%;
    align-items: center;
}

.link-tile:hover .link-tile-title{
    visibility: visible;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@media all and (-ms-high-contrast:none) {
    .profile-tile-icon {
        width: 100px;
    }

    .profile-tile-name {
        width: 160px;
    }
}