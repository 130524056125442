@use '_colors';

.btn-base {
    border: none;
    font-family: 'Roboto Condensed', 'sans-serif';
    cursor: pointer;
}

.login-btn {
    @extend .btn-base;
    background-color: colors.$login-btn-bg;
    color: white;
    width: 25%;
    padding: 1rem;
    font-size: 1.25rem;
    &:hover {
        background-color: colors.$login-btn-bg-hover;
    }
}

.app-user-btn {
    @extend .btn-base;
    background-color: colors.$main-user-btn;
    color: colors.$main-white;
    text-transform: uppercase;
    font-size: 1rem;
    width: 75%;
    padding: 1rem;
    font-weight: bold;
    &:hover {
        background-color: colors.$main-user-btn-hover;
    }
}

.app-support-btn {
    @extend .btn-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$app-tile-btn;
    color: colors.$main-white;
    text-transform: uppercase;
    font-size: 1.25rem;
    width: 75%;
    padding: 1rem 1.5rem 1rem 1.5rem;
    &:hover {
        background-color: colors.$app-tile-btn-hover;
    }
}

.modal-standard {
    @extend .btn-base;
    background-color: colors.$login-btn-bg;
    padding: 1rem 1.25rem;
    color: white;
    margin-right: 1rem;
    font-size: 1rem;
}

.modal-secondary {
    @extend .btn-base;
    background-color: colors.$main-secondary-btn;
    padding: 1rem 1.25rem;
    margin-left: 1rem;
    color: white;
}