@use '_colors';
@use '_pages';
@use '_buttons';
@use '_modal';
@use '_tiles';

body {
    margin: 0;
    height: 100vh;
    font-family: 'Roboto Condensed', 'sans-serif';
	font-weight: 300;
}

a {
    color: colors.$main-white;
}

a:hover {
    color: #ffffff93;
}